import React from 'react';

import InteractiveViewSection from '../../../small_views/interactive_view_section/interactive_view_section';

import data from './eta_interaction_phone_data';

import classes from './eta_interaction_phone_section.module.css';

const EtaInteractivePhoneSection = () => (
	<InteractiveViewSection
		title={(
			<>
				{'Des possibilités infinies,'}
				<br />
				{'une utilisation optimale'}
			</>
		)}
		showDownloadsLinks
		viewType="phone"
		viewDeviceProps={{
			className: classes.viewDevice
		}}
		{...{ data }}
	/>
);

export default EtaInteractivePhoneSection;
