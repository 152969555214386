import PhilippeGouillartPicture from '../../../../assets/images/pages/solutions/eta/testimonials_section/philippe_gouillart/philippe_gouillart.png';
import PhilippeGouillartPictureLowDef from '../../../../assets/images/pages/solutions/eta/testimonials_section/philippe_gouillart/philippe_gouillart_low_def.png';

import HenriBenardPicture from '../../../../assets/images/pages/solutions/eta/testimonials_section/henri_benard/henri_benard.png';
import HenriBenardPictureLowDef from '../../../../assets/images/pages/solutions/eta/testimonials_section/henri_benard/henri_benard_low_def.png';

export default {
	philippeGouillart: {
		url: 'https://www.youtube.com/watch?v=IgnfKt6yojM',
		tag: 'ETA GOUILLART',
		name: 'Philippe Gouillart',
		title: 'Gérant',
		preview: PhilippeGouillartPicture,
		previewLowDef: PhilippeGouillartPictureLowDef,
    videoDuration: '3:05'
	},
	henriBenard: {
		url: 'https://www.youtube.com/watch?v=q8IUpZt0ros',
		tag: 'Bonduelle',
		name: 'Henri Benard',
		title: 'Responsable des achats agronomiques',
		preview: HenriBenardPicture,
		previewLowDef: HenriBenardPictureLowDef,
    videoDuration: '2:22'
	}
};
