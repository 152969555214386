import React from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import InteractiveViewSection from '../../../small_views/interactive_view_section/interactive_view_section';
import DefaultActionsButtons from '../../../small_views/interactive_view_section/default_actions_buttons/default_actions_buttons';

import data from './eta_interaction_desktop_data';

import classes from './eta_interaction_desktop_section.module.css';

const EtaInteractiveDesktopSection = () => {
	const displayButtonsAsSectionChild = useMediaQuery('(max-width: 1300px)', { defaultMatches: true });
	return (
		<InteractiveViewSection
			reverseContent
			viewType="desktop"
			sectionClassName={classes.section}
			titleClassName={classes.title}
			title="Toujours plus de fonctionnalités"
			{...!displayButtonsAsSectionChild && {
				viewDeviceProps: {
					children: (
						<DefaultActionsButtons
							className={classes.buttonsContainer}
							buttonClassName={classes.button}
						/>
					)
				}
			}}
			{...{ data }}
		>
			{displayButtonsAsSectionChild && (
				<DefaultActionsButtons />
			)}
		</InteractiveViewSection>
	)
};

export default EtaInteractiveDesktopSection;
