import React from 'react';

import HelmetLayout from '../../components/layouts/helmet_layout/helmet_layout';
import MainLayout from '../../components/layouts/main_layout/main_layout';
import WithGradientLayout from '../../components/layouts/with_gradient_layout/with_gradient_layout';

import EtaHeader from '../../components/solutions/eta/eta_header/eta_header';
import EtaInteractivePhoneSection from '../../components/solutions/eta/eta_interactive_phone_section/eta_interactive_phone_section';
import EtaInteractiveDesktopSection from '../../components/solutions/eta/eta_interactive_desktop_section/eta_interactive_desktop_section';
import EtaTestimonialsSection from '../../components/solutions/eta/eta_testimonials_section/eta_testimonials_section';

const Eta = () => (
	<HelmetLayout>
		<MainLayout
			footerProps={{
				dismissedSections: ['create-demo-account']
			}}
		>
			<EtaHeader />
			<WithGradientLayout>
				<EtaInteractivePhoneSection />
				<EtaInteractiveDesktopSection />
			</WithGradientLayout>
			<EtaTestimonialsSection />
		</MainLayout>
	</HelmetLayout>
);

export default Eta;
